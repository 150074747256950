<template>
    <div>
        <h3 class="text-denim-700 text-2xl font-normal text-center">Log in</h3>
        <form @submit.prevent="logIn" class="mt-8">
            <!-- Login Form -->
            <div v-if="!otpRequested">
                <label for="email" class="text-grey-700 font-semibold block w-full" style="font-size: 14px">
                    Email address
                </label>
                <input
                    v-model="username"
                    type="email"
                    id="email"
                    class="mt-1 border bg-grey-50 border-grey-500 rounded-sm w-full py-2 px-4 focus:outline-none focus:border-cobalt-500"
                />
                <div class="mt-2">
                    <PasswordInput v-model="password" />
                </div>
            </div>

            <!-- OTP Input Form (if needed) -->
            <div v-if="otpRequested">
                <label for="otp" class="text-grey-700 font-semibold block w-full" style="font-size: 14px">
                    {{ otpMessage }}
                </label>
                <div v-if="otpTimeRemaining" class="text-sm text-grey-600 mt-1">
                    It expires in {{ otpTimeRemaining }}
                </div>
                <input
                    v-model="otp"
                    type="text"
                    id="otp"
                    class="mt-1 border bg-grey-50 border-grey-500 rounded-sm w-full py-2 px-4 focus:outline-none focus:border-cobalt-500"
                />
            </div>

            <!-- Error Message -->
            <div class="text-sm mt-2 text-rose-500" v-if="loginStatus.meta && loginStatus.meta[`login.${loginStatus.value}`]">
                {{ loginStatus.meta[`login.${loginStatus.value}`].message }}
            </div>


            <!-- Submit Button -->
            <button
                :class="determineLoadingState"
                class="mt-4 bg-cobalt-500 rounded-sm w-full py-2 px-4 text-white font-semibold test-shadow"
            >
                {{ otpRequested ? "Submit OTP" : "Log in" }}
            </button>
        </form>

        <div class="mt-6 text-center">
            Don't have an account?
            <router-link to="/signup" class="font-semibold text-cobalt-500">Sign up</router-link>
        </div>
    </div>
</template>
<script>
import { Machine, interpret } from "xstate";
import PasswordInput from "@/components/inputs/PasswordInput/PasswordInput.vue";
import { mapGetters } from "vuex";
import axios from "axios";
import endpoints from "@/endpoints";

const loginMachine = Machine({
    id: "login",
    initial: "idle",
    states: {
        idle: { on: { SUBMIT_LOGIN: "attemptingLogin" } },
        attemptingLogin: {
            on: {
                SUCCESS: "otpRequested",
                UNVERIFIED_ACCOUNT: "unverifiedAccount",
                INCORRECT_PASSWORD: "incorrectPassword",
                ACCOUNT_MARKED_FOR_DELETION: "accountMarkedForDeletion",
                UNSPECIFIED_ERROR: "unspecifiedError",
            },
        },
        otpRequested: {
            on: { SUBMIT_OTP: "attemptingOtp" },
        },
        attemptingOtp: {
            on: {
                OTP_SUCCESS: "loginSucceeded",
                OTP_FAILED: "incorrectOtp",
            },
        },
        loginSucceeded: {
            type: "final",
            entry: ["resetMeta"], // Reset meta state after success
        },
        unverifiedAccount: { meta: { message: "Your account is not yet verified." } },
        incorrectPassword: { meta: { message: "Incorrect email or password." } },
        accountMarkedForDeletion: { meta: { message: "Your account has been marked for deletion." } },
        unspecifiedError: { meta: { message: "An error occurred while logging in." } },
        incorrectOtp: {
            meta: { message: "The OTP you entered is incorrect. Please try again." },
            on: { SUBMIT_OTP: "attemptingOtp" },
        },
    },
});

export default {
    components: { PasswordInput },
    data() {
        return {
            username: "",
            password: "",
            otp: "",
            otpMessage: "We've sent you an email with your OTP. Please check your inbox.",
            otpRequested: false,
            loginService: interpret(loginMachine),
            loginStatus: loginMachine.initialState,
            otpExpiresIn: null, // original value in seconds
            otpCountdown: null, // actual timer value
            otpTimer: null, // for clearing the interval
        };
    },
    computed: {
        ...mapGetters(["profiles"]),
        determineLoadingState() {
            return { loading: this.loginStatus.value === "attemptingLogin" || this.loginStatus.value === "attemptingOtp" };
        },
        otpTimeRemaining() {
            if (this.otpCountdown === null) return null;
            const minutes = Math.floor(this.otpCountdown / 60);
            const seconds = this.otpCountdown % 60;
            return `${minutes}:${seconds.toString().padStart(2, "0")}`;
        },
    },
    created() {
        this.loginService
            .onTransition(async (state) => {
                this.loginStatus = state;

                if (state.value === "otpRequested" || state.value === "loginSucceeded" || state.value === "attemptingOtp") {
                    // Clear error messages on successful transitions
                    this.loginStatus.meta = {};
                }

                if (state.value === "loginSucceeded") {
                    console.log("loginSucceeded");

                    const redirect = this.$auth.redirect();
                    await this.$auth.login({
                        data: {
                            _username: this.username,
                            _password: this.password,
                        },
                        redirect: {
                            path: redirect ? redirect.from.fullPath : "dashboard",
                        },
                    });

                    await this.$store.dispatch("getAccountData");
                    await this.$store.dispatch("getProfiles");
                }
            })
            .start();
    },
    beforeDestroy() {
        if (this.otpTimer) {
            clearInterval(this.otpTimer);
        }
    },
    methods: {
        send(event) {
            this.loginService.send(event);
        },
        async logIn() {
            if (!this.otpRequested) {
                this.loginService.send("SUBMIT_LOGIN");

                try {
                    const response = await this.$auth.login({
                        data: { _username: this.username, _password: this.password },
                    });

                    // Default fallback message, in case backend doesn't send one
                    this.otpMessage = response.data.message || "We've sent you an email with your OTP. Please check your inbox.";
                    // Save expiration (in seconds)
                    this.otpExpiresIn = response.data.otpExpiresIn || null;
                    if (this.otpExpiresIn) {
                        this.startOtpCountdown(this.otpExpiresIn);
                    }
                    this.otpRequested = true;
                    this.loginService.send("SUCCESS");
                    // Clear any lingering errors
                    this.loginStatus.meta = {};
                } catch (err) {
                    this.handleLoginError(err);
                }
            } else {
                this.loginService.send("SUBMIT_OTP");

                try {
                    const response = await axios.post(endpoints.verifyOtp, {
                        _username: this.username,
                        _otp: this.otp,
                    });

                    this.$auth.token(response.data.token);
                    this.loginService.send("OTP_SUCCESS");

                    // Clear errors after successful OTP
                    this.loginStatus.meta = {};
                } catch (err) {
                    this.loginService.send("OTP_FAILED");
                    // Grab backend message (e.g. "Invalid OTP") and display it
                    if (err.response && err.response.data && err.response.data.message) {
                        this.loginStatus.meta = {
                            [`login.${this.loginStatus.value}`]: {
                                message: err.response.data.message,
                            },
                        };
                    } else {
                        // Fallback message
                        this.loginStatus.meta = {
                            [`login.${this.loginStatus.value}`]: {
                                message: "An error occurred during OTP verification.",
                            },
                        };
                    }

                    console.error("OTP verification error:", err.response ? err.response.data : err.message);

                }
            }
        },
        handleLoginError(err) {
            if (err.response && err.response.status === 401 && err.response.data.message === "User account is disabled.") {
                this.loginService.send("UNVERIFIED_ACCOUNT");
            } else if (err.response && err.response.status === 401) {
                this.loginService.send("INCORRECT_PASSWORD");
            } else if (err.response && err.response.status === 404) {
                this.loginService.send("ACCOUNT_MARKED_FOR_DELETION");
            } else {
                this.loginService.send("UNSPECIFIED_ERROR");
                console.error(err);
            }
        },
        startOtpCountdown(seconds) {
            this.otpCountdown = seconds;

            if (this.otpTimer) {
                clearInterval(this.otpTimer);
            }

            this.otpTimer = setInterval(() => {
                if (this.otpCountdown > 0) {
                    // eslint-disable-next-line no-plusplus
                    this.otpCountdown--;
                } else {
                    clearInterval(this.otpTimer);
                    this.otpTimer = null;
                }
            }, 1000);
        },
    },
};
</script>
